import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class PriceService {
  private apiPrefix = '/public/payments/prices';

  constructor(
    private api: ApiService,
  ) {
  }

  getActivePrice() {
    return this.api.get(`${this.apiPrefix}/web`);
  }

  getActivePlanPrices() {
    return this.api.get(`${this.apiPrefix}`);
  }
}
