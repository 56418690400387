import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { PushNotificationService } from "./core/services/notification/push-notification.service";
import { ThemeService } from "./core/services/theme.service";
import { isPlatformBrowser } from "@angular/common";
import { Meta } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { filter } from "rxjs";
import { ScriptsService } from "./core/services/tracker/scripts.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'gray-frontend';
  isBrowser: boolean;

  constructor(
    private translate: TranslateService,
    private pushNotification: PushNotificationService,
    private themeService: ThemeService,
    private router: Router,
    private modalService: NgbModal,
    private scriptsService: ScriptsService,
    private metaService: Meta,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    // Subscribe router events
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Dismiss all open modals when the route changes
        this.modalService.dismissAll("Route change");
      } else if (event instanceof NavigationEnd) {
        // Update meta tags
        this.updateMetaTags();
      }
    });

    if (this.isBrowser) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((r) => {
        const eventInfo = r as NavigationEnd;
        if (!eventInfo.url.includes('dashboard')) {
          document?.getElementById('body')?.scrollTo(0, 0);
        }
      });
    }
  }

  updateMetaTags() {
    let currentRoute = this.activatedRoute.root;
    let metaData = currentRoute.snapshot.data['meta'];

    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
      const currentRouteMetaData = currentRoute.snapshot.data['meta'];
      if (currentRouteMetaData)
        metaData = currentRouteMetaData;

    }

    if (metaData) {
      if (isPlatformBrowser(this.platformId)) {
        this.metaService.updateTag({ name: metaData.name, content: metaData.content });
      } else {
        this.metaService.addTag({ name: metaData.name, content: metaData.content });
      }
    }
  }

  ngOnInit(): void {
    this.translate.addLangs(['tr', 'en']);
    this.translate.use('tr');
    this.scriptsService.loadCookieBotScript();
  }

  changeColor() {
    console.log(this.themeService.current);
    const color = (this.themeService.current == 'default') ? 'green' : 'default';
    this.themeService.current = color;
  }

  ngAfterViewInit(): void {
  }
}
