import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { ELikeType } from "../../enums/like-type.enum";

@Injectable()
export class VideoService {
  private apiPrefix = '/client/videos';

  constructor(
    private api: ApiService,
  ) {
  }

  getUserManualVideos(queryString = '') {
    return this.api.get(`${this.apiPrefix}/user-manual${queryString}`);
  }

  getLastVideos(queryString = '') {
    return this.api.get(`${this.apiPrefix}${queryString}`);
  }

  getVideoWithId(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}`);
  }

  getRelatedVideos(id: number, queryString = '') {
    return this.api.get(`${this.apiPrefix}/${id}/related-videos${queryString}`);
  }

  storeVideoHistory(videoId: number, data: object) {
    return this.api.post(`${this.apiPrefix}/${videoId}/history`, data);
  }

  createVideoLikeStatus(videoId: number, likeType: ELikeType) {
    return this.api.patch(`${this.apiPrefix}/${videoId}/like-dislike`, {
      likeType,
    });
  }

  deleteVideoLikeStatus(videoId: number) {
    return this.api.delete(`${this.apiPrefix}/${videoId}/like-dislike`);
  }
}
